import moment from 'moment';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatterNumber } from '~/components/commons/CurrencyFormat/CurrencyFormat';
import { PaymentMethod } from '~/contants/order';
import { DeliverProduct, OrderDetail, Payment, Voucher } from '~/types/orderDetailType';
import { BaseUtils } from '~/ultils/base-utils';
import QRCode from 'qrcode.react';
import { calcTotalPriceOrder } from '~/ultils/order-utils';

const containerWidth = 530;
const EMPTY = '--';

const SVGLine = ({ width }: any) => (
  <svg width="600" height="15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <line
      stroke-dasharray="10, 5"
      x1="0"
      y1="10"
      x2={width || containerWidth}
      y2="10"
      style={{ strokeWidth: '2px', stroke: 'rgb(0, 0, 0)' }}
    ></line>
  </svg>
);

const View = ({
  orderDetail,
  isReadyScreenshot,
}: {
  orderDetail: OrderDetail;
  isReadyScreenshot: boolean;
}) => {
  const { header } = orderDetail || {};
  let [searchParams] = useSearchParams();

  const { vouchers, promotions } = header || {};

  const { customer, payment, deliveryAddress, codPenceExchange, extraPayments } = header || {};

  const { penceAmount, pointAmount } = codPenceExchange || {};
  const {
    productItems: products,
  } = orderDetail?.delivery || {};

  const { priceAmount: shippingPrice, discountAmount: shippingDiscount } =
    orderDetail?.header?.shipping || {};

  const { method } = payment || {};

  const  { invoiceCode } = header || {}

  const { storeAddress } = orderDetail?.delivery || {};

  const totalProduct = useMemo(() => {
    return Math.round(
      products?.reduce(
        (previousValue: any, currentValue: any) => previousValue + currentValue.quantity,
        0
      )
    );
  }, [products]);

  const totalPrice = useMemo(() => {
    return calcTotalPriceOrder(products);
  }, [products]);

  const shippingPriceTotal = Number(shippingPrice || 0);

  const shippingDiscountTotal = shippingDiscount;

  const { incurredPayment } = header || {};
  const { type, amount: amountIncurredPayment } = incurredPayment || {};
  const rightWidth = '220px';

  const renderIncurredPayment: any = useMemo(() => {
    let arr: any = [];
    if (type === 'COD') {
      arr.push({
        label: 'Tiền khách trả thêm',
        amount:
          type === 'COD' ? Number(amountIncurredPayment || 0) + Number(penceAmount || 0) : null,
      });
    }
    if (type === 'REFUND') {
      arr.push({
        label: 'Số tiền hoàn trả khách',
        amount: type === 'REFUND' ? amountIncurredPayment : null,
      });
    }

    if (method === PaymentMethod.CASH_ON_DELIVERY) {
      arr?.splice(0, 1);
      return arr;
    }
    return arr;
  }, [amountIncurredPayment, method, type]);

  const totalDiscountOrder = useMemo(() => {
    return (
      [...(promotions || []), ...(vouchers || [])]?.reduce(
        (previousValue: any, currentValue: any) => {
          return previousValue + Number(currentValue.discountAmount || 0);
        },
        0
      ) || 0
    );
  }, [promotions, vouchers]);

  const totalPriceWithVoucher = useMemo(() => {
    return Number(totalPrice) - Number(totalDiscountOrder || 0) > 0
      ? Number(totalPrice) - Number(totalDiscountOrder || 0)
      : 0;
  }, [totalPrice, totalDiscountOrder]);

  const totalShiping = useMemo(() => {
    return Number(shippingPrice || 0) - Number(shippingDiscount || 0);
  }, [shippingDiscount, shippingPrice]);

  const amountCaculator = useMemo(() => {
    return totalPriceWithVoucher + totalShiping;
  }, [totalPriceWithVoucher, totalShiping]);

  const totalExtraPayment = useMemo(() => {
    return extraPayments?.reduce((previousValue: any, currentValue: any) => {
      return previousValue + Number(currentValue?.amount || 0);
    }, 0);
  }, [extraPayments]) || 0;

  const amountCustomerNeedToPay = useMemo(() => {
    return amountCaculator - totalExtraPayment;
  }, [totalExtraPayment, amountCaculator]);

  const moneyNotVoucher = useMemo(() => {
    return products?.reduce((previousValue: any, currentValue: any) => {
      return (
        previousValue + Number(currentValue.originPrice || 0) * Number(currentValue.quantity || 0)
      );
    }, 0);
  }, [products]);

  const saveMoney =
    Math.abs(Number(moneyNotVoucher)) -
    Math.abs(Number(totalPrice)) +
    Math.abs(Number(totalDiscountOrder)) +
    Math.abs(Number(shippingDiscountTotal || 0));

  const productGifts = useMemo(() => {
    return (
      products?.reduce((previousValue: any, currentValue: any) => {
        console.log(currentValue.gifts, 'currentValue.gifts');
        const curr = currentValue.gifts || [];
        return [...previousValue, ...curr];
      }, []) || []
    );
  }, [products]);

  const taxCode = searchParams.get('taxCode');
  const shouldShowTaxCode = Boolean(taxCode);

  const genVATUrl = searchParams.get('genVATUrl');

  return (
    <div
      id={isReadyScreenshot ? 'screenshot-ready' : ''}
      style={{
        fontFamily: 'Inter',
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        id="invoice-wp"
      >
        <div
          id="invoice"
          style={{ width: `${containerWidth}px` }}
          className={isReadyScreenshot ? 'screenshot-ready' : ''}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img width={300} src="https://oms.seedcom.vn/logo-kfm.png" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span
                  className="truncate text-ellipsis"
                  style={{ maxWidth: containerWidth, textAlign: 'center' }}
                >
                  {storeAddress}
                </span>
                <span>Hotline: 18006804</span>
              </div>
              <div style={{ margin: '2px 0px', width: '100%' }}>
                <SVGLine />
              </div>
              <h1 style={{ fontSize: '30px', marginTop: '10px', margin: 0, fontWeight: 'bold' }}>
                HOÁ ĐƠN BÁN HÀNG
              </h1>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '16px',
              }}
            >
              <div className="flex  justify-between">
                <span>Số HD</span>
                <span>{invoiceCode || searchParams.get('invoiceCode') || EMPTY}</span>
              </div>
              <div className="flex  justify-between">
                <span>Ngày đặt đơn</span>
                <span>{moment(orderDetail?.header?.orderTime).format('DD/MM/YYYY HH:mm')}</span>
              </div>
              <div className="flex justify-between">
                <span>Mã đơn hàng</span>
                <span>{orderDetail?.header?.code}</span>
              </div>
              {shouldShowTaxCode && (
                <div className="flex justify-between">
                  <span>Mã CQT</span>
                  <span>{taxCode || EMPTY}</span>
                </div>
              )}
              <div className="flex justify-between">
                <span>Khách hàng</span>
                <span>{customer?.name} </span>
              </div>
              <div className="flex justify-between">
                <span>Số điện thoại</span>
                <span>
                  {customer?.phone?.toString()?.slice(0, customer?.phone?.length - 4)} ****
                </span>
              </div>

              <div className="flex justify-between">
                <span>Người nhận</span>
                <span>{deliveryAddress?.name || EMPTY}</span>
              </div>
              <div className="flex justify-between">
                <span>Số điện thoại</span>
                <span>
                  {deliveryAddress?.phone
                    ? ` ${deliveryAddress?.phone
                        ?.toString()
                        ?.slice(0, deliveryAddress?.phone?.length - 4)} ****`
                    : EMPTY}
                </span>
              </div>
              <div
                className="truncate text-ellipsis"
                style={{ maxWidth: containerWidth, textAlign: 'center' }}
              >
                <span className="">Địa chỉ: {deliveryAddress?.fullAddress || EMPTY}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: '10px 0px',
                  marginTop: '20px',
                  padding: '10px 0px',
                  borderTop: '2px solid #111111',
                  borderBottom: '2px solid #111111',
                }}
              >
                <div style={{ width: '54%', fontWeight: 'bold', textAlign: 'left' }}>Đơn giá</div>
                <div style={{ width: '23%', fontWeight: 'bold' }}>SL</div>
                <div
                  style={{
                    width: '23%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Thành tiền
                </div>
              </div>
              {products?.map((product: any, index) => {
                const { originPrice, sellPrice } = product || {};
                const discount = Math.floor(
                  ((Number(originPrice) - Number(sellPrice)) / Number(originPrice)) * 100
                );
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {!!product.type && <div>{product.type}</div>}
                    <div style={{ width: '100%', fontWeight: 'bolder' }}>
                      {product?.name} {product.unit && `(${product?.unit})`}{' '}
                      {product?.gifts?.length && '(Quà tặng kèm)'}
                      {product?.sellPrice === 0 && '(Quà tặng 0đ)'}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div style={{ width: '54%' }}>
                        {sellPrice === originPrice && (
                          <span>{formatterNumber(originPrice) || 0}</span>
                        )}
                        {sellPrice !== originPrice && (
                          <>
                            <span
                              style={{
                                textDecoration: 'line-through',
                                marginRight: '10px',
                              }}
                            >
                              {formatterNumber(originPrice) || 0}
                            </span>
                            <span>{formatterNumber(sellPrice) || 0}</span>
                            {Boolean(discount) && (
                              <span
                                style={{
                                  marginLeft: '10px',
                                }}
                              >
                                (-{discount}%)
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div style={{ width: '23%' }}>{product?.quantity}</div>
                      <div style={{ width: '23%', textAlign: 'right', fontWeight: 'bold' }}>
                        <span>
                          {' '}
                          {formatterNumber(
                            Number(sellPrice || 0) * Number(product.quantity || 0)
                          ) || 0}
                        </span>
                      </div>
                    </div>
                    {products?.length - 1 !== index && (
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SVGLine />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div style={{ width: '100%' }}>
              <SVGLine />
            </div>
            {productGifts?.length > 0 && (
              <>
                <span className="font-bold mb-2">Quà tặng</span>
                {productGifts?.map((deliverProduct: DeliverProduct) => {
                  return <div className="flex flex-col gap-2">{deliverProduct?.name}</div>;
                })}
                <div style={{ width: '100%' }}>
                  <SVGLine />
                </div>
              </>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  marginTop: '15px',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>Tổng số lượng:</span>
                <span style={{ width: rightWidth }}>{totalProduct}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>Tổng tiền hàng:</span>
                <span style={{ width: rightWidth }}>{formatterNumber(totalPrice) || 0}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>Phí vận chuyển:</span>
                <span style={{ width: rightWidth }}>
                  {formatterNumber(shippingPriceTotal) || 0}
                </span>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                  Giảm phí vận chuyển:
                </span>
                <span style={{ width: rightWidth }}>
                  {Boolean(shippingDiscountTotal) && '-'}
                  {formatterNumber(shippingDiscountTotal as number) || 0}
                </span>
              </div>
              {vouchers?.map((voucher: Voucher) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                      {voucher?.name}:
                    </span>
                    <span style={{ width: rightWidth }}>
                      -{formatterNumber(voucher?.discountAmount || 0)}
                    </span>
                  </div>
                );
              })}
              {promotions?.map((promotion: Voucher) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                    {promotion?.name}:
                  </span>
                  <span style={{ width: rightWidth }}>
                    -{formatterNumber(promotion?.discountAmount || 0)}
                  </span>
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '15px',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {Boolean(saveMoney) && (
                <span style={{ fontSize: '22px', alignSelf: 'flex-end', whiteSpace: 'nowrap' }}>
                  Tiết kiệm {formatterNumber(saveMoney) || 0}
                </span>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>Tổng cộng:</span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      display: 'flex',
                      flexDirection: 'column',
                      width: rightWidth,
                    }}
                  >
                    {formatterNumber(amountCaculator < 0 ? 0 : amountCaculator) || 0}
                  </span>
                </div>
              </div>
              {codPenceExchange && Number(penceAmount || 0) > 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                    Thối tiền thừa bằng điểm:
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                        width: rightWidth,
                      }}
                    >
                      <span className="font-medium">
                        ({BaseUtils.formatNumber(pointAmount || 0)} điểm) &nbsp;
                        {BaseUtils.formatNumber(penceAmount || 0)}
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {extraPayments?.map((payment: Payment) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                    {payment?.methodName}:
                  </span>
                  <span style={{ width: rightWidth }}>
                    {formatterNumber(payment?.amount || 0) || EMPTY}
                  </span>
                </div>
              ))}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <span style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                  {method === PaymentMethod.CASH_ON_DELIVERY
                    ? 'Khách cần thanh toán:'
                    : 'Khách đã thanh toán:'}
                </span>
                <span style={{ width: rightWidth }}>
                  {method === PaymentMethod.CASH_ON_DELIVERY
                    ? formatterNumber(
                        Number(amountCustomerNeedToPay || 0) + Number(penceAmount || 0) || 0
                      ) || EMPTY
                    : formatterNumber(payment?.amount || 0) || EMPTY}
                </span>
              </div>
              {renderIncurredPayment?.map((obj: any) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  <div style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                    <span>{obj?.label}:</span>
                  </div>
                  <div style={{ width: rightWidth }}>
                    <span>{(obj?.amount && formatterNumber(obj?.amount as number)) || EMPTY}</span>
                  </div>
                </div>
              ))}

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <div style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                  <span>Tổng điểm còn lại:</span>
                </div>
                <div style={{ width: rightWidth }}>
                  <span>--</span>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                <div style={{ flexGrow: 1, width: 'calc(100% - 220px)' }}>
                  <span>Phương thức thanh toán:</span>
                </div>
                <div style={{ width: rightWidth }}>
                  <span>{payment?.methodName || EMPTY}</span>
                </div>
              </div>
            </div>
          </div>
          {genVATUrl && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '16px',
              }}
            >
              <span className="text-center mb-2">
                Quét mã QR bên dưới để nhập thông tin Y/c xuất hóa đơn trong vòng 2 giờ từ khi tính
                tiền và trước 23 giờ cùng ngày
              </span>
              <div>
                <QRCode renderAs="canvas" size={160} value={genVATUrl} />
              </div>
            </div>
          )}
          <div className={`flex flex-col ${!genVATUrl ? 'mt-5' : 'mt-2'} `}>
            <span className="text-center">Thông tin đổi trả và khiếu nại vui lòng truy cập:</span>
            <span className="text-center" style={{ fontSize: '20px' }}>
              https://kingfoodmart.com/kingfoodmart/refund-policy
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default View;
