import { CheckCircleTwoTone, ScanOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Empty, Row, message } from 'antd';
import { Html5Qrcode } from 'html5-qrcode';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import Loading from '~/components/loading';
import { SERVER_PREFIX } from '~/contants/commons';
import { useStoreCompleteOrder } from '~/queries/commons/useStoreCompleteOrder';
import { SHARE_SECRET_KEY } from '~/queries/orders/useOrderDetailQuery';
import { BaseUtils } from '~/ultils/base-utils';
import { OrderBagLabel, OrderBagType } from '../types';
import { useDeliveryOrderBagsContext } from './context';
import './styles.scss';
import { onBackWebView } from '../utils';
import { StatusOfOrder } from '~/contants/order';
import { useMarkAsScannedOrderBag } from '~/queries/commons/markAsScannedOrderBag';
import { useGetOrderBags } from '~/queries/commons/useGetOrderBags';

export declare var messageHandler: any;

enum ORDER_TYPE {
  CUSTOMER_PICKUP = 'CUSTOMER_PICKUP',
  SHIPPER_DELIVERY = 'SHIPPER_DELIVERY',
}

const Item = ({ code, isDone }) => {
  return (
    <Row align="middle" gutter={15} className="mt-5">
      <Col span={14}>
        <div className="flex gap-2 items-center">
          <ShoppingOutlined style={{ color: 'gray', fontSize: 19 }} />
          <span className="font-medium font-weight-bold text-gray-500">{code}</span>
        </div>
      </Col>
      <Col span={8}>&nbsp;</Col>
      <Col span={2}>
        <div className="text-right">
          <CheckCircleTwoTone
            twoToneColor={isDone ? '#52c41a' : '#b6b6b6'}
            style={{
              fontSize: 21,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

const TypeBagItem = ({ label, data }) => {
  const allDone = data?.filter((dt) => dt.isDone || dt.lastScannedTime)?.length === data.length;

  if (!data.length) return <></>;
  return (
    <div className="bg-white px-3 py-5 mt-4">
      <Row align="middle" gutter={15}>
        <Col span={14}>
          <span className="font-medium font-weight-bold" style={{ fontSize: 16 }}>
            {label}
          </span>
        </Col>
        <Col span={8}>
          <div className="flex items-center gap-1 text-gray-500">
            <span className="font-medium">{data?.length || 0}</span>
            <ShoppingOutlined style={{ fontSize: 20 }} />
          </div>
        </Col>
        <Col span={2}>
          <div className="text-right">
            <CheckCircleTwoTone
              twoToneColor={allDone ? '#52c41a' : '#b6b6b6'}
              style={{
                fontSize: 21,
              }}
            />
          </div>
        </Col>
      </Row>
      {data?.length ? (
        data?.map(({ code, isDone, lastScannedTime }) => {
          return (
            <React.Fragment key={code}>
              <Item
                {...{
                  code,
                  isDone: isDone || lastScannedTime,
                }}
              />
            </React.Fragment>
          );
        })
      ) : (
        <>
          <Empty description={<span>Không có dữ liệu</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </>
      )}
    </div>
  );
};

const CustomerInfo = ({ totalBag, cod, customer, statusName }) => {
  return (
    <div className="bg-white px-3 gap-y-3 py-5">
      <div className="flex flex-col flex-y-2">
        <div className="flex justify-between">
          <span className="text-gray-500">Trạng thái</span>
          <Badge color="rgb(45, 183, 245)" text={statusName} />
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Khách hàng</span>
          <span className="font-medium">{customer.name}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">SDT</span>
          <span className="font-medium">{customer.phone}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-gray-500">COD</span>
          <span>{BaseUtils.formatNumber(cod || 0)}đ</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Số túi</span>
          <span className="font-medium">{totalBag}</span>
        </div>
      </div>
    </div>
  );
};

const DeiveryOrderBags = () => {
  const [searchParams] = useSearchParams();

  const { mutate: markAsScannedOrderBag } = useMarkAsScannedOrderBag({});

  const orderCode: any = searchParams.get('orderCode');
  const employeeName = searchParams.get('employeeName');

  const { data: orderBags } = useGetOrderBags({ orderCode });

  const codesScanned = useMemo(
    () =>
      orderBags
        ?.flatMap((orderBag) => {
          if (orderBag.lastScannedTime) {
            return orderBag.code;
          }
        })
        .filter(Boolean),
    [orderBags]
  );

  const html5QrcodeScannerRef: any = useRef();
  const html5QrcodeScannerContainerRef: any = useRef();

  const [isScan, setIsScan] = useState(false);

  const { deliveryOrderBags, header, onSetDeliveryOrderBags, isLoadingDeliveryOrderDetail } =
    useDeliveryOrderBagsContext();

  const { type } = header || {};

  const queryClient = useQueryClient();

  const handleCompleteBag = () => {
    onStoreCompleteOrder({
      secretKey: SHARE_SECRET_KEY,
      orderCode,
      employee: {
        name: employeeName,
      },
    });
  };

  const mergeBags =
    [...deliveryOrderBags.DRY, ...deliveryOrderBags?.FRESH, ...deliveryOrderBags?.FROZEN].filter(
      Boolean
    ) || [];

  const totalBags = mergeBags?.length;

  const mergeBagsDone = mergeBags
    .map((bag) => {
      if (bag.isDone || bag.lastScannedTime) return bag.code;
    })
    .filter(Boolean);

  const {
    mutate: onStoreCompleteOrder,
    isSuccess: isSuccessStoreCompleteOrder,
    data: completeStoreCompleteOrder,
    isLoading: loadingStoreCompleteOrder,
  } = useStoreCompleteOrder();

  useEffect(() => {
    if (completeStoreCompleteOrder?.error) {
      message.error(SERVER_PREFIX + completeStoreCompleteOrder?.error);
    } else if (isSuccessStoreCompleteOrder) {
      queryClient.invalidateQueries(['orderShareDetail']);
      message.success('Cập nhật túi thành công');
      setTimeout(() => {
        onBackWebView();
      }, 1000);
    }
  }, [completeStoreCompleteOrder, isSuccessStoreCompleteOrder, queryClient]);

  const stopCamera = () => {
    html5QrcodeScannerContainerRef.current.innerHTML = '';
    if (html5QrcodeScannerRef.current.isScanning) {
      html5QrcodeScannerRef.current
        .stop()
        .then(() => {
          html5QrcodeScannerRef.current.clear();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      html5QrcodeScannerRef.current.clear();
    }
  };

  const startCamera = () => {
    html5QrcodeScannerRef.current.resume();
  };

  const handleScan = () => {
    setIsScan(!isScan);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qrCodeSuccessCallback = (decodedText: string, decodedResult: any) => {
    // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);
    const trimDecodedText = decodedText?.trim();

    let type = '';

    if (trimDecodedText.includes('FZ')) {
      type = 'FROZEN';
    }
    if (trimDecodedText.includes('FR')) {
      type = 'FRESH';
    }
    if (trimDecodedText.includes('DR')) {
      type = 'DRY';
    }

    setIsScan(false);
    stopCamera();
    if (mergeBagsDone?.includes(trimDecodedText)) {
      message.warn(`Túi ${trimDecodedText} đã được scan trước đó`);
    } else if (type) {
      message.success(`Đã check túi ${trimDecodedText} thành công`);
      markAsScannedOrderBag({
        bagCode: trimDecodedText,
        secretKey: SHARE_SECRET_KEY,
        orderCode: orderCode,
        employee: {
          name: employeeName,
        },
      });
      const bagsType = deliveryOrderBags[type].map((deliveryOrderBag) => {
        if (trimDecodedText === deliveryOrderBag.code) {
          return {
            ...deliveryOrderBag,
            isDone: true,
          };
        }
        return {
          ...deliveryOrderBag,
        };
      });

      onSetDeliveryOrderBags?.((currentState) => ({
        ...currentState,
        [type]: [...bagsType],
      }));
    } else if (!trimDecodedText.includes(orderCode)) {
      message.warning(`Không tồn tại mã túi ${trimDecodedText} trong đơn hàng`);
    }
  };

  const getCounterIsDone = useMemo(() => {
    let total = 0;
    ['FROZEN', 'FRESH', 'DRY'].map((type) => {
      return (total += deliveryOrderBags[type].filter(
        (deliveryOrderBag) => deliveryOrderBag?.isDone
      )?.length);
    });
    return total;
  }, [deliveryOrderBags]);

  useEffect(() => {
    if (isScan) {
      html5QrcodeScannerContainerRef.current = document.createElement('div');
      html5QrcodeScannerContainerRef.current.setAttribute('id', 'readerOrderBags');

      const appendCamera = document.getElementById('append-camera');
      appendCamera?.append(html5QrcodeScannerContainerRef.current);

      html5QrcodeScannerRef.current = new Html5Qrcode('readerOrderBags');

      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      html5QrcodeScannerRef.current.start(
        { facingMode: 'environment' },
        config,
        qrCodeSuccessCallback
      );

      try {
        startCamera();
      } catch (error) {
        console.log(error);
      }
    }
  }, [isScan]);

  useEffect(() => {
    return () => {
      if (html5QrcodeScannerRef.current) {
        try {
          html5QrcodeScannerRef.current.clear();
        } catch (error) {
          alert(JSON.stringify(error));
        }
      }
    };
  }, []);

  if (isLoadingDeliveryOrderDetail) return <Loading />;

  const dryDone = deliveryOrderBags.DRY.filter(
    (deliveryOrderBag) => !deliveryOrderBag.isDone
  )?.length;
  const fzDone = deliveryOrderBags.FROZEN.filter(
    (deliveryOrderBag) => !deliveryOrderBag.isDone
  )?.length;
  const frDone = deliveryOrderBags.FRESH.filter(
    (deliveryOrderBag) => !deliveryOrderBag.isDone
  )?.length;

  const allowSubmitComplete = dryDone === 0 && fzDone === 0 && frDone === 0;

  let btnSubmitText = 'Hoàn thành';

  if (type === ORDER_TYPE.SHIPPER_DELIVERY) {
    btnSubmitText = 'Giao cho shipper';
  }

  if (type === ORDER_TYPE.CUSTOMER_PICKUP) {
    btnSubmitText = 'Giao cho khách';
  }

  return (
    <>
      <div className="order-bags">
        <>
          {isScan && (
            <>
              <div className="modal-scan flex flex-col justify-between">
                <div id="append-camera" style={{ width: '100%' }}></div>
                <Button
                  onClick={() => {
                    stopCamera();
                    setIsScan(false);
                  }}
                  type="default"
                  style={{ height: 50 }}
                >
                  Trở lại
                </Button>
              </div>
            </>
          )}
          <div className="header flex gap-2 items-center justify-between px-4">
            <div className="__left flex gap-2 items-center cursor-pointer">
              <span className="font-medium ml-5" style={{ fontSize: 17 }}>
                {orderCode} - Giao cho khách
              </span>
            </div>
            <div className="__right flex gap-2 items-center">
              <span className="font-bold">
                {getCounterIsDone}/{totalBags}
              </span>
              <Button
                type="text"
                onClick={handleScan}
                icon={<ScanOutlined style={{ fontSize: 20 }} />}
              ></Button>
            </div>
          </div>
          <div className="content-scroll pb-4">
            <CustomerInfo
              totalBag={totalBags}
              cod={header?.codAmount}
              customer={header?.customer}
              statusName={header?.statusName}
            />
            <TypeBagItem
              label={OrderBagLabel.FROZEN}
              data={deliveryOrderBags?.[OrderBagType.FROZEN]}
            />
            <TypeBagItem
              label={OrderBagLabel.FRESH}
              data={deliveryOrderBags?.[OrderBagType.FRESH]}
            />
            <TypeBagItem label={OrderBagLabel.DRY} data={deliveryOrderBags?.[OrderBagType.DRY]} />
          </div>
          {header?.status !== StatusOfOrder.COMPLETED && (
            <div className="footer flex justify-between">
              <Button
                onClick={handleCompleteBag}
                loading={loadingStoreCompleteOrder}
                // disabled={!allowSubmitComplete}
                // style={{ flex: 1, borderRadius: 0, opacity: !allowSubmitComplete ? 0.3 : 1 }}
                style={{ flex: 1, borderRadius: 0 }}
                className="bg-orange-500 h-full"
                type="text"
              >
                <span style={{ textTransform: 'uppercase' }} className="text-white text-lg">
                  {btnSubmitText}
                </span>
              </Button>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default DeiveryOrderBags;
