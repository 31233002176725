import {
  ApiOutlined,
  AppstoreAddOutlined,
  FileDoneOutlined,
  HomeOutlined,
  LineChartOutlined,
  ShoppingOutlined,
  StarOutlined,
  ToolOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export type NavItem = {
  key: string;
  icon: React.ReactNode;
  permissions?: boolean | string;
  label?: React.ReactNode | string;
  children?: {
    key: string;
    permissions: boolean | string;
    label: React.ReactNode | string;
  }[];
};

export const _nav = [
  {
    key: '/',
    icon: React.createElement(HomeOutlined),
    permissions: 'true',
    label: <Link to="/">Home</Link>,
  },
  {
    key: '/order/list-order',
    icon: React.createElement(ShoppingOutlined),
    permissions: 'true',
    label: <Link to="/order/list-order">Đơn hàng</Link>,
  },
  {
    key: '/order/order-rating',
    permissions: 'true',
    icon: React.createElement(StarOutlined),
    label: <Link to="/order/order-rating">Đánh giá đơn hàng</Link>,
  },
  {
    key: 'monitor-order',
    icon: React.createElement(ApiOutlined),
    permissions: 'true',
    label: 'Quản lý đơn hàng (monitor)',
    children: [],
  },
  {
    key: 'customer',
    permissions: 'true',
    icon: React.createElement(UserSwitchOutlined),
    label: 'Khách hàng',
    children: [
      {
        key: '/customer/list-customer',
        permissions: 'true',
        label: <Link to="/customer/list-customer">Danh sách khách hàng</Link>,
      },
      {
        key: '/customer/call-history',
        permissions: 'true',
        label: <Link to="/customer/call-history">Lịch sử cuộc gọi</Link>,
      },
      {
        key: '/customer/call-ticket',
        permissions: 'true',
        label: <Link to="/customer/call-ticket">Ticket cuộc gọi</Link>,
      },
    ],
  },
  {
    key: 'bill',
    permissions: 'true',
    icon: React.createElement(FileDoneOutlined),
    label: 'Phiếu xử lý',
    children: [
      {
        key: '/bill/list-bill',
        permissions: 'true',
        label: <Link to="/bill/list-bill">Phiếu hoàn tiền</Link>,
      },
      {
        key: '/bill/list-sorry-voucher',
        permissions: 'true',
        label: <Link to="/bill/list-sorry-voucher">Sorry Voucher</Link>,
      },
    ],
  },
  {
    key: 'inventory',
    permissions: 'true',
    icon: React.createElement(LineChartOutlined),
    label: 'Inventory',
    children: [
      {
        key: '/inventory/po',
        permissions: 'true',
        label: <Link to="/inventory/po">PO</Link>,
      },
      {
        key: '/inventory/po-stock-tracking',
        permissions: 'true',
        label: <Link to="/inventory/po-stock-tracking">PO Stock Tracking</Link>,
      },
      {
        key: '/inventory/sale-stock-tracking',
        permissions: 'true',
        label: <Link to="/inventory/sale-stock-tracking">Sale Stock Tracking</Link>,
      },
    ],
  },
  {
    key: 'product-operation',
    permissions: 'true',
    icon: React.createElement(AppstoreAddOutlined),
    label: 'Product Operation',
    children: [
      {
        key: '/product-operation/products',
        permissions: 'true',
        label: <Link to="/product-operation/products">Danh sách sản phẩm</Link>,
      },
    ],
  },
  {
    key: 'system-setting',
    permissions: 'true',
    icon: React.createElement(ToolOutlined),
    label: 'Quản lý',
    children: [
      {
        key: '/system-setting/warehouse-products',
        permissions: 'true',
        label: <Link to="/system-setting/warehouse-products">Cấu hình stock</Link>,
      },
      {
        key: '/system-setting/sla-setting',
        permissions: 'true',
        label: <Link to="/system-setting/sla-setting">Cấu hình SLA</Link>,
      },
      {
        key: '/system-setting/employee-setting',
        permissions: 'true',
        label: <Link to="/system-setting/employee-setting">Quản lý nhân viên</Link>,
      },
      {
        key: '/system-setting/store-manage',
        permissions: 'true',
        label: <Link to="/system-setting/store-manage">Quản lý cửa hàng</Link>,
      },
      {
        key: '/system-setting/internal-shipper',
        permissions: 'true',
        label: <Link to="/system-setting/internal-shipper">Tài xế nội bộ</Link>,
      },
      {
        key: '/system-setting/cs-task',
        permissions: 'true',
        label: <Link to="/system-setting/cs-task">CS Task</Link>,
      },
    ],
  },
];
