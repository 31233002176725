import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';

const reasonOptions = [
  {
    id: 'Sản phẩm không kinh doanh',
    name: 'Sản phẩm không kinh doanh',
  },
  {
    id: 'Sản phẩm không được bán',
    name: 'Sản phẩm không được bán',
  },
  {
    id: 'Khách hàng yêu cầu bỏ sản phẩm',
    name: 'Khách hàng yêu cầu bỏ sản phẩm',
  },
  {
    id: 'Trùng mã sản phẩm quà tặng',
    name: 'Trùng mã sản phẩm quà tặng',
  },
  {
    id: -1,
    name: 'Khác',
  },
];

const DeleteConfirmation = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ reason: number | string }>();
  const reason = Form.useWatch('reason', form);

  useEffect(() => {
    form.setFieldsValue({
      reason: 'Sản phẩm không kinh doanh',
    });
  }, [form, isModalOpen]);

  return (
    <Modal
      footer={false}
      centered
      title={false}
      visible={isModalOpen}
      width={450}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onOk}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <div className="flex flex-col items-center gap-3">
          <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
          <span className="font-medium text-center">Bạn có muốn xoá sản phẩm này?</span>
          <Form.Item name="reason" noStyle>
            <Select
              fieldNames={{
                value: 'id',
                label: 'name',
              }}
              className="w-100"
              options={reasonOptions}
            />
          </Form.Item>
          {reason === -1 && (
            <Form.Item
              name="reasonOther"
              className='w-100'
              rules={[{ required: true, message: 'Trường này không được để rỗng' }]}
            >
              <TextArea placeholder="Nhập lý do" style={{width: "100%" }} />
            </Form.Item>
          )}
          <div className="flex gap-2 mt-5 m-auto">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteConfirmation;
