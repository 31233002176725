import { UserOutlined } from '@ant-design/icons';

import { Avatar, Button, Menu, Space } from 'antd';
import classNames from 'classnames';
import styles from './IHeader.module.scss';

const cx = classNames.bind(styles);

export const userMenu = (onLogout: () => void, { name, username, role }: AuthUser) => (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <a
            target="_blank"
            className={cx('user-menu')}
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            <Space>
              <Avatar icon={<UserOutlined />} />
              <div className={cx('user-des')}>
                <span>
                  <strong>{name}</strong>
                </span>
                <div>{username} - {role}</div>
              </div>
            </Space>
          </a>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '6',
        label: (
          <Button onClick={onLogout} style={{ width: '100%' }} type="primary">
            Logout
          </Button>
        ),
      },
    ]}
  />
);
